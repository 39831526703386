<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getAccountStatement, downloadExcel } from "@/state/helpers";
import {
  FETCH_ACCOUNT_STATEMENT,
  DOWNLOAD_VA_EXCEL_STATEMENT,
} from "@/state/actions.type";
import * as moment from "moment";
import * as cloneDeep from "lodash.clonedeep";
import Spinner from "vue-simple-spinner";
import DownloadBtn from '../../../components/download-btn.vue';

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    DownloadBtn,
  },
  computed: {
    maxToDate() {
      return moment().format("DD-MM-YYYY");
    },
    minToDate() {
      return moment(this.chaiDate.from).format("DD-MM-YYYY");
    },
    maxFromDate() {
      return moment(this.chaiDate.to).format("DD-MM-YYYY");
    },
    minFromDate() {
      return moment().subtract("years", 2).format("DD-MM-YYYY");
    },
    title() {
      return this.$t("menuitems.payouts.list.virtual_account_txns");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          active: true
        },
        {
          text: this.$t("menuitems.payouts.list.virtual_account"),
          href: '/payouts/virtual-account'
        },
        {
          text: this.$t("menuitems.transactions.text")
        },
      ];
    },

    rows() {
      return this.$store.state.accountstatement.total_elements;
    },

    accountsDataFromStore() {
      return this.$store.state.accountstatement.accountStatement;
    },

    isFetchingTransactions() {
      return this.$store.state.accountstatement.isFetchingData;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    perPage: function (val) {
      this.refreshRecord();
    },
    // eslint-disable-next-line no-unused-vars
    currentPage: function (val) {
      this.refreshRecord();
    },

    accountsDataFromStore() {
      this.vaAccountTransactionsData = cloneDeep(this.accountsDataFromStore);
    },
  },

  onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  },

  methods: {
    ...downloadExcel,
    ...getAccountStatement,
    openModal() {
      this.showmodal = true;
    },

    hideModal() {
      this.showmodal = false;
    },

    sortByDate(a,b, key) {
      if(key == 'date') {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1-d2;
      }
    },

    handleSubmit() {
      const excelDate = {
        dates: {
          from: this.chaiStatementDate.from + "T00:00:00.000",
          to: this.chaiStatementDate.to + "T23:59:59.000",
        },
        account_no: this.$route.query.account_no,
      };

      this.$store.dispatch(
        `accountstatement/${DOWNLOAD_VA_EXCEL_STATEMENT}`,
        excelDate
      );
      this.showmodal = false;
    },

    refreshRecord() {
      const postData = {
        dates: {
          //from: moment(this.chaiDate.from, "YYYY-MM-DD").toISOString().replace("Z", ""),
          //to: moment(this.chaiDate.to, "YYYY-MM-DD").add('days', 1).toISOString().replace("Z", ""),
          from: this.chaiDate.from + "T00:00:00.000",
          to: this.chaiDate.to + "T23:59:59.000",
          page: this.currentPage,
          pagesize: this.perPage,
        },
        account_no: this.$route.query.account_no,
      };
      this.$store.dispatch(
        `accountstatement/${FETCH_ACCOUNT_STATEMENT}`,
        postData
      );
    },
  },

  mounted() {
    // Set the initial number of items
    this.vaAccountTransactionsData = cloneDeep(this.accountsDataFromStore);
  },

  created() {
    this.refreshRecord();
    this.vaAccountTransactionsData = cloneDeep(this.accountsDataFromStore);
  },

  data() {
    return {
      showmodal: false,
      // TODO: Fetch channel and method data by APIs
      advancedFilterClosed: true,
      chaiDate: {
        from: moment().subtract(1, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },

      chaiStatementDate: {
        from: moment().subtract(1, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },

      vaAccountTransactionsData: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [2, 10, 25, 50, 100],
      filters: {
        pmt_channel: [],
        pmt_method: [],
        status: [],
      },
      mor_filter: "",
      filterOn: [],
      sortBy: "date",
      sortDesc: true,

      fields: [
        { key: "date", sortable: true, label: "views.payouts.table.fields.date", tooltip: "views.payouts.table.fields.date" },
        { key: "vaaccount_no", sortable: true, label: "views.payouts.table.fields.vaaccount_no", tooltip: "views.payouts.table.fields.vaaccount_no" },
        { key: "amount", sortable: true, label: "views.payouts.table.fields.txn_amount", tooltip: "views.payouts.table.fields.txn_amount" },
        { key: "txn_type", sortable: true, label: "views.payouts.table.fields.txn_type", tooltip: "views.payouts.table.fields.txn_type"},
        { key: "balance", sortable: true, label: "views.payouts.table.fields.balance", tooltip: "views.payouts.table.fields.balance"},
      ],
    };
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  {{ $t("views.payouts.all_transaction") }}
                </template>
                <div class="d-flex justify-content-between my-4">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      <span class="text-nowrap">{{ $t("common.show") }}</span>&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;<span class="text-nowrap">{{ $t("common.entries") }}</span>
                    </label>
                  </div>

                  <div class="d-flex">
                    <form style="display: flex" class="ml-1">
                      <input
                        :max="maxFromDate"
                        :min="minFromDate"
                        style="max-width: 200px"
                        type="date"
                        v-model="chaiDate.from"
                        class="form-control form-control-sm col-md-5"
                        @change="refreshRecord()"
                      />
                      <input
                        style="max-width: 200px"
                        :max="maxToDate"
                        :min="minToDate"
                        v-model="chaiDate.to"
                        type="date"
                        class="form-control form-control-sm col-md-5 ml-1"
                        @change="refreshRecord()"
                      />
                    </form>

                    <span>
                      <div @click="openModal">
                        <DownloadBtn />
                      </div>
                    </span>
                  </div>
                </div>

                <div class="table-responsive text-center table-custom">
                  <b-table
                    class="table-centered"
                    :items="vaAccountTransactionsData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-compare="sortByDate"
                    :sort-desc.sync="sortDesc"
                    :bordered="true"
                    :filter-included-fields="filterOn"
                    sticky-header="500px"
                    show-empty
                    :empty-text="$t('common.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:head()="data">
                      <span v-b-tooltip.hover.top :title="$t(data.field.tooltip)">
                        {{ $t(data.label) }}
                      </span>
                    </template>

                    <template v-slot:cell(amount)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(balance)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(txn_type)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Debit',
                          'badge-soft-success': `${row.value}` === 'Credit'
                        }"
                      >{{ row.value}}</div>
                    </template>

                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Download excel modal -->
    <div>
      <b-modal
        id="modal"
        centered
        v-model="showmodal"
        :title="$t('common.download')"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modal" action="#" @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="startdate">{{ $t('common.start_date') }}</label>
            <input
              type="date"
              v-model="chaiStatementDate.from"
              class="form-control"
              name="startdate"
            />
          </div>

          <div class="form-group">
            <label for="enddate">{{ $t('common.end_date') }}</label>
            <input
              type="date"
              v-model="chaiStatementDate.to"
              class="form-control"
              name="enddate"
            />
          </div>

          <div class="text-right">
            <!-- <button type="submit" class="btn btn-primary ml-1 btn-sm">
              {{ $t("views.payouts.pdf") }}
            </button> -->
            <b-button class="mr-1" variant="light" size="sm" @click="showmodal = false">{{ $t('button.close') }}</b-button>
            <button type="submit" class="btn btn-primary ml-1 btn-sm">
              {{ $t("views.payouts.excel") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #transaction-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}
</style>


#transaction-filter-collapse > div > div > div > div:nth-child(1) > div > div.multiselect__tags > div.multiselect__tags-wrap > span





